import React from 'react';
import {
  BackgroundContainer,
  BackgroundImage,
  BackgroundImageSecond,
  BackgroundImagePink,
} from '@/components/Tokenomics/TokenomicsBackground/TokenomicsBackground.styled';

const TokenomicsBackground = () => (
  <BackgroundContainer>
    <BackgroundImage />
    <BackgroundImageSecond />
    <BackgroundImagePink />
  </BackgroundContainer>
);
export default TokenomicsBackground;
