import React from 'react';
import VectorLeftMiddle from '@/images/VectorLeftMiddle.svg';
import VectorRightMiddle from '@/images/VectorRightMiddle.svg';
import {
  TokenomicsContainer,
  TokenomicsTitleWrapper,
  TokenomicsTitle,
  TokenomicsImage,
  TokenomicsSubtitle,
  FundAllocationContainer,
  FundAllocationTitle,
  FundAllocationStatistic,
  FundAllocationDiagram,
  FundAllocationDiagramItem,
  FundAllocationStatisticItem,
  // BackgroundColors,
  FundAllocationStatisticRound,
  FundAllocationStatisticText,
} from '@/components/Tokenomics/Tokenomics.styled';
import TableComponent from '@/components/UI/AmmunitionTypes/Table/Table';
import TokenomicsBackground from '@/components/Tokenomics/TokenomicsBackground/TokenomicsBackground';

import { useIsMobile } from '@/hooks/useMobile';
import {
  rowsFirstTable,
  columnsFirstTable,
  rowsSecondTable,
  columnsSecondTable,
  allocation,
  secondTableBadges,
  firstTableBadges,
} from './constans';

const Tokenomics = () => {
  const isMobile = useIsMobile();

  return (
    <TokenomicsContainer id="tokenomics">
      <TokenomicsTitleWrapper>
        <TokenomicsImage src={VectorLeftMiddle} />
        <TokenomicsTitle>Tokenomics schedule</TokenomicsTitle>
        <TokenomicsImage src={VectorRightMiddle} />
      </TokenomicsTitleWrapper>
      <TokenomicsSubtitle>
        NFTs Blockchain, NFTs, and Tokens create a game world genuinely owned by
        the players. Nearly every armory asset is an NFT or token at launch, and
        the few that are not will be tokenised in the future. Players can take
        their assets to the in-game or secondary marketplaces to trade them or
        gift them. It’s all in the hands of the players or collectioneers!
      </TokenomicsSubtitle>
      <TokenomicsBackground />
      <TableComponent
        badges={firstTableBadges}
        isTokenomicsPage
        rows={rowsFirstTable}
        columns={columnsFirstTable}
      />
      <TableComponent
        isTokenomicsPage
        badges={secondTableBadges}
        rows={rowsSecondTable}
        columns={columnsSecondTable}
      />
      {isMobile && (
        <TableComponent
          isTokenomicsPage
          badges={secondTableBadges}
          rows={rowsSecondTable.slice(3)}
          columns={columnsSecondTable}
        />
      )}
      <FundAllocationContainer>
        <FundAllocationTitle>Fund allocation</FundAllocationTitle>
        <FundAllocationStatistic>
          {allocation.map((item, index) => (
            <FundAllocationStatisticItem key={index}>
              <FundAllocationStatisticRound background={item.background} />
              <FundAllocationStatisticText>
                {item.title}
              </FundAllocationStatisticText>
            </FundAllocationStatisticItem>
          ))}
        </FundAllocationStatistic>
        <FundAllocationDiagram>
          {allocation.map((item, index) => (
            <FundAllocationDiagramItem
              key={index}
              width={item.width}
              background={item.background}
            />
          ))}
        </FundAllocationDiagram>
      </FundAllocationContainer>
    </TokenomicsContainer>
  );
};

export default Tokenomics;
