import styled from 'styled-components';

export const BackgroundContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
`;

export const BackgroundImage = styled.div`
  position: absolute;
  max-width: 989px;
  width: 100%;
  height: 989px;
  left: 699px;
  top: 337px;
  z-index: -10;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(81, 97, 213, 0.3) 0%,
    rgba(34, 34, 131, 0) 100%
  );
  @media (max-width: 390px) {
    display: none;
  }
`;

export const BackgroundImageSecond = styled.div`
  position: absolute;
  max-width: 1442px;
  width: 100%;
  height: 1442px;
  left: 540px;
  top: 2007px;
  z-index: -10;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(81, 97, 213, 0.3) 0%,
    rgba(34, 34, 131, 0) 100%
  );
  @media (max-width: 390px) {
    display: none;
  }
`;

export const BackgroundImagePink = styled.div`
  position: absolute;
  max-width: 1002px;
  width: 100%;
  height: 1000px;
  left: -129px;
  owerflow: hidden;
  top: 2907px;
  z-index: -1;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #843164 26.04%,
    rgba(53, 28, 88, 0) 100%
  );
  opacity: 0.5;
`;
