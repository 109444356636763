import React, { FC, useState } from 'react';
import {
  Badge,
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableHeadColumn,
  TableHeadColumnDropdown,
  TableHeadColumnDropdownBtnText,
  TableRow,
} from '@/components/UI/AmmunitionTypes/Table/Table.styled';

import { useIsMobile } from '@/hooks/useMobile';

interface ITableComponentProps {
  rows: any;
  columns: string[];
  badges?: string[];
  isTokenomicsPage?: boolean;
}

const TableComponent: FC<ITableComponentProps> = ({
  rows,
  columns,
  badges,
  isTokenomicsPage,
}) => {
  const [currentRows, setCurrentRows] = useState<any>(rows);
  const [sorted, setSorted] = useState<boolean>(false);
  const isMobile = useIsMobile();

  const handleRowsSort = () => {
    if (!sorted) {
      setCurrentRows([...currentRows].sort((a, b) => a.value4 - b.value4));
      setSorted(true);
    } else {
      setCurrentRows(rows);
      setSorted(false);
    }
  };
  return (
    <Table isTokenomicsPage>
      <TableHead>
        {!isMobile ? (
          <>
            {columns.map((column, idx) => (
              <TableHeadColumn itemsCount={columns.length} key={idx}>
                {column}
              </TableHeadColumn>
            ))}
          </>
        ) : isTokenomicsPage ? (
          <>
            <TableHeadColumn itemsCount={4} />
            <TableHeadColumn itemsCount={4}>
              {currentRows[0].value}
            </TableHeadColumn>
            <TableHeadColumn itemsCount={4}>
              {currentRows[1].value}
            </TableHeadColumn>
            {currentRows[2] && (
              <TableHeadColumn itemsCount={4}>
                {currentRows[2].value}
              </TableHeadColumn>
            )}
          </>
        ) : (
          <>
            <TableHeadColumn itemsCount={columns.length}>
              {columns[0]}
            </TableHeadColumn>
            <TableHeadColumn itemsCount={columns.length}>
              {columns[1]}
            </TableHeadColumn>
            <TableHeadColumnDropdown onClick={handleRowsSort}>
              <TableHeadColumnDropdownBtnText>
                {columns[3]}
              </TableHeadColumnDropdownBtnText>
            </TableHeadColumnDropdown>
          </>
        )}
      </TableHead>
      <TableBody>
        {!isMobile &&
          currentRows?.map((row, idx) => (
            <TableRow key={`${row.value}-${idx}`}>
              <TableColumn itemsCount={columns.length}>
                <span>
                  {row.value}
                  <Badge> {` ${isTokenomicsPage ? badges[idx] : ''}`}</Badge>
                </span>
              </TableColumn>
              <TableColumn itemsCount={columns.length}>
                {row.value2}
              </TableColumn>
              <TableColumn itemsCount={columns.length}>
                {row.value3}
              </TableColumn>
              <TableColumn itemsCount={columns.length}>
                {row.value4.toString().replace('.', ',')}
              </TableColumn>
              {columns.length > 5 && (
                <TableColumn itemsCount={columns.length}>
                  {row.value5}
                </TableColumn>
              )}
              {columns.length > 6 && (
                <TableColumn itemsCount={columns.length}>
                  {row.value6}
                </TableColumn>
              )}
              {columns.length > 6 && (
                <TableColumn itemsCount={columns.length}>
                  {row.value7}
                </TableColumn>
              )}
              {columns.length > 7 && (
                <TableColumn itemsCount={columns.length}>
                  {row.value8}
                </TableColumn>
              )}
            </TableRow>
          ))}
        {isMobile &&
          (isTokenomicsPage ? (
            <>
              {columns
                .filter(
                  (column) =>
                    column !== 'Name' &&
                    column !== 'Distribution Schedule Token',
                )
                .map((column, idx) => (
                  <TableRow key={`${column}-${idx}`}>
                    <TableColumn itemsCount={4}>{column}</TableColumn>
                    <TableColumn itemsCount={4}>
                      {Object.values(currentRows[0])[idx + 1]}
                    </TableColumn>
                    <TableColumn itemsCount={4}>
                      {Object.values(currentRows[1])[idx + 1]}
                    </TableColumn>
                    {currentRows[2] && (
                      <TableColumn itemsCount={4}>
                        {Object.values(currentRows[2])[idx + 1]}
                      </TableColumn>
                    )}
                  </TableRow>
                ))}
            </>
          ) : (
            currentRows.map((row, idx) => (
              <TableRow key={`${row.value}-${idx}`}>
                <TableColumn itemsCount={columns.length}>
                  {row.value}
                </TableColumn>
                <TableColumn itemsCount={columns.length}>
                  {row.value2}
                </TableColumn>
                <TableColumn itemsCount={columns.length}>
                  <span>{row.value4.toString().replace('.', ',')}</span>
                </TableColumn>
              </TableRow>
            ))
          ))}
      </TableBody>
    </Table>
  );
};

export default TableComponent;
