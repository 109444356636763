import styled from 'styled-components';

export enum BackgroundColors {
  DEFAULT = '#C72D92',
  LIGHT_BLUE = ' #486DD3',
  BLUE = '#1E1BB4',
  RED = '#B31A48;',
  VIOLET = '#634496',
  GREY = '#798094',
}

interface IFundAllocationDiagramItem {
  background?: string;
  width: string;
}

interface IFundAllocationStatisticRound {
  background?: string;
}

export const TokenomicsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 207px;
  @media (max-width: 768px) {
    margin-top: 120px;
  }
`;

export const TokenomicsTitleWrapper = styled.div`
  display: flex;
  max-width: 1230px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1200px) {
    display: flex;
    justify-content: center;
  }
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const TokenomicsTitle = styled.h1`
  font-weight: 600;
  font-size: 80px;
  text-align: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.white};
  @media (max-width: 768px) {
    font-size: 50px;
    margin-top: 50px;
  }
`;

export const TokenomicsSubtitle = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
  max-width: 810px;
  width: 100%;
  margin-top: 30px;
  color: ${(props) => props.theme.colors.gray};
`;

export const TokenomicsImage = styled.img`
  @media (max-width: 1200px) {
    display: none;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 50px;
`;
export const FundAllocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 150px;
  padding: 10px;
  align-items: center;
  max-width: 1230px;
  width: 100%;
  @media (max-width: 390px) {
    margin-top: 116px;
  }
`;

export const FundAllocationTitle = styled.div`
  font-weight: 600;
  font-size: 50px;
  text-align: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.white};
  @media (max-width: 740px) {
    font-size: 30px;
  }
`;

export const FundAllocationStatistic = styled.div`
  margin: 40px auto 0;
  display: flex;
  max-width: 1070px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1000px) {
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    height: 70px;
  }
  @media (max-width: 747px) {
    height: 80px;
  }
  @media (max-width: 390px) {
    margin-top: 16px;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    height: 80px;
  }
`;
export const FundAllocationStatisticItem = styled.div`
  display: flex;
  height: 24px;
  max-width: 200px;
  width: 100%;
  align-items: center;
  justify-content: center;
  @media (max-width: 390px) {
    max-width: 130px;
    justify-content: flex-start;
    &:nth-child(1) {
      order: 0;
    }
    &:nth-child(2) {
      order: 1;
    }
    &:nth-child(3) {
      order: 4;
    }
    &:nth-child(4) {
      order: 5;
    }
    &:nth-child(5) {
      order: 2;
    }
  }
`;

export const FundAllocationStatisticRound = styled.div<IFundAllocationStatisticRound>`
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin-right: 10px;
  @media (max-width: 390px) {
    &:nth-child(3) {
      order: 2;
    }
  }
  ${(props) => {
    const selectedBackground = props.background || BackgroundColors.DEFAULT;

    return `
        background-color: ${selectedBackground};
        color: ${selectedBackground};
      `;
  }}
`;

export const FundAllocationStatisticText = styled.div`
  font-weight: 400;
  font-size: 20px;
  color: ${(props) => props.theme.colors.white};
  @media (max-width: 390px) {
    font-size: 15px;
  }
`;

export const FundAllocationDiagram = styled.div`
  margin-top: 30px;
  max-width: 1230px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-flow: row;
  align-items: center;
  width: 100%;
  background: ${(props) => props.theme.colors.absoluteBlack};
  border-radius: 20px;
  @media (max-width: 740px) {
    padding: 15px;
  }
  @media (max-width: 390px) {
    padding: 10px;
  }
`;

export const FundAllocationDiagramItem = styled.div<IFundAllocationDiagramItem>`
  border-radius: 10px;
  height: 40px;
  margin: 0 2.5px;

  ${(props) => {
    const selectedBackground = props.background || BackgroundColors.DEFAULT;

    return `
        background-color: ${selectedBackground};
        color: ${selectedBackground};
        width: ${props.width};
      `;
  }}
`;
