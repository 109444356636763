import { BackgroundColors } from '@/components/Tokenomics/Tokenomics.styled';

export const rowsFirstTable = [
  {
    value: 'TGE month',
    value2: '5%',
    value3: '10%',
    value4: '20%',
    value5: '4%',
    value6: '25%',
    value7: '0%',
    value8: '4%',
  },
  {
    value: 'Cliff',
    value2: '-',
    value3: '1 month',
    value4: '-',
    value5: '-',
    value6: '-',
    value7: '12 months',
    value8: '-',
  },
  {
    value: 'Vesting',
    value2: '25 months',
    value3: '25 months',
    value4: '4 months',
    value5: '36 months',
    value6: '25 months',
    value7: '36 months',
    value8: '36 months',
  },
];

export const firstTableBadges = [', %', ', months', ', months'];

export const columnsFirstTable = [
  'Name',
  'Seed Round',
  'Private Round',
  'Launchpads',
  'Adoption incentives',
  'Listing / Liquidity',
  'Team & Advisors',
  'Marketing & Development',
];

export const secondTableBadges = [
  ', %',
  '1,000,000,000',
  '',
  '$18,000,000',
  '$2,900,000',
  '$598,500',
];

export const rowsSecondTable = [
  {
    value: 'Percent',
    value2: '5.00%',
    value3: '10.00%',
    value4: '5.00%',
    value5: '40.00%',
    value6: '5.00%',
    value7: '17.50%',
    value8: '17.50%',
  },
  {
    value: 'Token Supply',
    value2: '50,000,000',
    value3: '100,000,000',
    value4: '50,000,000',
    value5: '400,000,000',
    value6: '50,000,000',
    value7: '175,000,000',
    value8: '175,000,000',
  },
  {
    value: 'Token Price',
    value2: '$0.010',
    value3: '$0.015',
    value4: '$0.018',
    value5: '-',
    value6: '-',
    value7: '-',
    value8: '-',
  },
  {
    value: 'Fully Diluted Valuation',
    value2: '$10,000,000',
    value3: '$15,000,000',
    value4: '$18,000,000',
    value5: '-',
    value6: '-',
    value7: '-',
    value8: '-',
  },
  {
    value: 'Total Capital Raise',
    value2: '$500,000',
    value3: '$1,500,000',
    value4: '$900,000',
    value5: '-',
    value6: '-',
    value7: '-',
    value8: '-',
  },
];

export const columnsSecondTable = [
  'Distribution Schedule Token',
  'Seed Round',
  'Private Round',
  'Launchpads',
  'Adoption incentives',
  'Listing / Liquidity',
  'Team & Advisors',
  'Marketing & Development',
];

export const allocation = [
  {
    title: '13% - Round 1',
    background: BackgroundColors.DEFAULT,
    width: '13%',
  },
  {
    title: '15% - Round 2',
    background: BackgroundColors.LIGHT_BLUE,
    width: '15%',
  },
  {
    title: '20% - Round 3',
    background: BackgroundColors.BLUE,
    width: '20%',
  },
  {
    title: '21% - Round 4',
    background: BackgroundColors.RED,
    width: '21%',
  },
  {
    title: '15%  - Mystery',
    background: BackgroundColors.VIOLET,
    width: '15%',
  },
  {
    title: '16%  - VIP',
    background: BackgroundColors.GREY,
    width: '16%',
  },
];
