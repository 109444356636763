import styled from 'styled-components';

export const Table = styled.div<{ isTokenomicsPage: boolean }>`
  margin-top: ${(props) => (props.isTokenomicsPage ? 40 : 40)}px;
  max-width: 1230px;
  z-index: 1;
  width: 100%;
  background: ${(props) => props.theme.colors.black};
  border: 2px solid #0a0c13;
  box-shadow: 0px 10px 40px 10px rgba(13, 16, 24, 0.5);
  border-radius: 30px;
  @media (max-width: 1230px) {
    width: 96%;
  }
  @media (max-width: 767px) {
    position: relative;
    width: 96%;
    border-radius: 0;
    border: 0;
    margin-top: ${(props) => (props.isTokenomicsPage ? 68 : 50)}px;
    margin-right: 10px;
    margin-left: 10px;
  }
`;
export const TableHead = styled.div`
  display: flex;
  height: 60px;
  padding-left: 20px;
  background: #0a0c13;
  align-items: center;
  border-radius: 30px 30px 0px 0px;
  @media (max-width: 767px) {
    background: none;
    top: -45px;
    width: 100%;
    position: absolute;
    &:nth-child(3) {
      flex-grow: 1;
    }
  }
`;
export const TableHeadColumn = styled.div<{ itemsCount: number }>`
  width: ${(props) => `${100 / props.itemsCount}%`};
  color: #798094;
  display: flex;
  text-align: center;
  font-size: calc(10px + 5 * (100vw - 420px) / 880);
  justify-content: center;
  margin-right: 25px;
  &:last-child {
    margin-right: 0;
  }
  &:first-child {
    text-align: left;
    justify-content: flex-start;
  }
  @media (max-width: 767px) {
    margin-right: 0;
    width: 29%;
    font-size: calc(12px + 10 * (100vw - 420px) / 880);
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    &:last-child {
      display: flex;
      justify-content: center;
      margin-left: 6%;
      margin-right: 5%;
      position: relative;
      @media (min-width: 450px) {
        justify-content: flex-end;
        & span {
          width: 90px;
          text-align: center;
        }
      }
    }
  }
  @media (min-width: 1230px) {
    font-size: 15px;
  }
`;

export const TableHeadColumnDropdown = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 38%;
  justify-content: flex-end;
  position: absolute;
  right: 4%;
`;

export const Badge = styled.span`
  font-size: calc(10px + 5 * (100vw - 420px) / 880);

  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: ${(props) => props.theme.colors.gray};

  @media (min-width: 1230px) {
    font-size: 15px;
  }
`;

export const TableHeadColumnDropdownBtn = styled.div<{ isSorted: boolean }>`
  width: 7px;
  height: 7px;
  border-top: 1px solid ${(props) => props.theme.colors.blue};
  border-right: 1px solid ${(props) => props.theme.colors.blue};
  margin-left: 5px;
  transform: ${(props) =>
    props.isSorted ? 'rotate(-45deg)' : 'rotate(135deg)'};
  transition: ease 0.2s;
`;

export const TableHeadColumnDropdownBtnText = styled.div`
  color: ${(props) => props.theme.colors.blue};
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: right;
  position: relative;
  &:after {
    content: '';
    width: 100%;
    height: 0.5px;
    background: ${(props) => props.theme.colors.blue};
    position: absolute;
    bottom: 3px;
    left: 0;
  }
`;

export const TableBody = styled.div`
  height: 100%;
`;

export const TableRow = styled.div`
  padding-left: 20px;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  &:nth-child(4n + 2) {
    background: #0a0c13;
  }
  &:nth-child(4) {
    background: #0a0c13;
  }
  &:nth-child(6) {
    border-radius: 0 0 20px 20px;
  }
  @media (max-width: 767px) {
    &:before {
      position: absolute;
      top: 0;
      left: 2.5%;
      width: 94%;
      border-top: 1px solid #798094;
      content: '';
      opacity: 0.3;
    }
    &:nth-child(4n + 2) {
      background: none;
    }
    &:nth-child(4) {
      background: none;
    }
    &:nth-child(odd) {
      background: #0a0c13;
    }
    &:nth-child(3) {
      background: #0a0c13;
    }
    &:last-child {
      &:after {
        position: absolute;
        bottom: 0;
        left: 2.5%;
        width: 94%;
        border-top: 1px solid #798094;
        opacity: 0.3;
        content: '';
      }
    }
    height: 60px;
  }
`;
export const TableColumn = styled.div<{ itemsCount: number }>`
  width: ${(props) => `${100 / props.itemsCount}%`};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  margin-right: 25px;
  font-size: calc(10px + 5 * (100vw - 420px) / 880);
  &:last-child {
    margin-right: 0;
  }
  &:first-child {
    text-align: left;
    justify-content: flex-start;
  }
  @media (min-width: 1230px) {
    font-size: 15px;
  }
  @media (max-width: 767px) {
    width: 60%;
    &:last-child {
      display: flex;
      justify-content: center;
      margin-left: 6%;
      margin-right: 5%;
      position: relative;
      @media (min-width: 450px) {
        justify-content: flex-end;
        & span {
          width: 90px;
          text-align: center;
        }
      }
    }

    margin-right: 0;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
  }
`;
