import React from 'react';

import { ThemeProvider } from '@/themes/theme';
import { GlobalStyle } from '@/themes/globalStyle';
import Wrapper from '@/components/Wrapper';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import Tokenomics from '@/components/Tokenomics/Tokenomics';
import VectorBackground from '@/components/VectorBackground/VectorBackground';
import GameReady from '@/components/GameReady/GameReady';
import GameReadyBackground from '@/components/GameReadyBackground/GameReadyBackground';
import TokenomicsBackground from '@/components/Tokenomics/TokenomicsBackground/TokenomicsBackground';
import favicon from '@/images/favicon.ico';
import { Helmet } from 'react-helmet';

const TokenomicsPage = () => (
  <ThemeProvider>
    <Helmet>
      <title>Sky Breakers Tokenomics</title>
      <link rel="icon" href={favicon} />
      <meta name="viewport" content="initial-scale=1, viewport-fit=cover" />
    </Helmet>
    <GlobalStyle />

    <VectorBackground>
      <Wrapper>
        <Header />
        <TokenomicsBackground />
        <Tokenomics />
        <GameReadyBackground>
          <GameReady hasMargin />
        </GameReadyBackground>
        <Footer />
      </Wrapper>
    </VectorBackground>
  </ThemeProvider>
);

export default TokenomicsPage;
